.welcome-container {
	min-height: 100vh;
	background-color: #b9e3e9;
	display: flex;
}

.welcome-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	background-color: white;
	width: 400px;
	margin: auto;
	border-radius: 15px;
}

.welcome-profile-img {
	display: flex;
	width: 200px;
	height: 200px;
	margin: 30px 0px;
}

.welcome-profile-img img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
	border-radius: 15px;
}