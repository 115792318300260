/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

.modal-60w {
  width: 60%;
  max-width: none !important;
}

.modal-70w {
  width: 70%;
  max-width: none !important;
}

.modal-80w {
  width: 80%;
  max-width: none !important;
}

.img-max {
  max-width: 100%;
  max-height: 100%;
}

.fd-green {
  color: #39b2bf;
}

.fd-bg-green {
  background-color: #39b2bf;
}

.pointer {
  cursor: pointer;
}

.custom-green-btn {
  /*width: 170px;*/
  text-align: center;
  background-color: #39b2bf;
  color: white;
  border-radius: 50px;
  padding: 3px 0px;
  font-size: 15px;
  font-weight: 700;
}

.custom-red-btn {
  /*width: 170px;*/
  text-align: center;
  background-color: #ec1c2b;
  color: white;
  border-radius: 50px;
  padding: 3px 0px;
  font-size: 15px;
  font-weight: 700;
}
