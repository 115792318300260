.phone-gig-bg {
	width: 350px;
	position: absolute;
	min-height: 100vh;
	background-image: url('../assets/img/phoneGig_bg.png');
	background-repeat: no-repeat;
	margin-left: -20px
	/*flex-direction: column;*/
	/*background-size: contain;*/
	/*background-position: left;*/
}

.status-bar-brand img {
	max-width: 100%;
	max-height: 100px;
	margin: auto;
}

.fake-btn {
	background-color: #1a98a6;
	pointer-events: none;
}

.fake-btn:hover {
	background-color: #1a98a6;
	pointer-events: none;
}

.what-to-say-content {
	background-color: white;
	height: 410px;
	padding: 20px 50px;
	overflow: scroll;
	overflow-x: hidden;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
}

.what-to-say-content::-webkit-scrollbar {
    width: 10px;
}

.what-to-say-content::-webkit-scrollbar-track {
    /*border-radius: 10px;*/
    background: #88d0d8;
}

.what-to-say-content::-webkit-scrollbar-thumb {
    /*border-radius: 10px;*/
    background: #39B2BE;
}

.phone-gig-map {
	height: 200px;
}

.contacts-table {
	width: 100%;
}

.contacts-table-header {
	background-color: #ababab;
	color: white;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	font-weight: 600;
	font-size: 12px;
	padding: 0 10px;
}

.contacts-table-header-cell {
}

.contacts-table-body {
	font-size: 12px;
	height: 220px;
	overflow: scroll;
	overflow-x: hidden;
}

.contacts-table-body::-webkit-scrollbar {
    width: 10px;
}

.contacts-table-body::-webkit-scrollbar-track {
    background: #fcdbe1;
}

.contacts-table-body::-webkit-scrollbar-thumb {
    background: #d91a1a;
}

.contacts-table-body-row {
	border-bottom: 1px solid #e9ecef;
	padding: 2px 0px;
}

.contacts-table-body-row:hover {
	background-color: #fcdbe1;
}

.contacts-table-body-cell {
	margin: auto;
	overflow: auto;
}

.big-call-btn {
	background-color: #f29d36;
	height: 38px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: 700;
}

.live-call-btn {
	/*-webkit-animation: live-call-animation 1s infinite; /* Safari 4+ */
	/*-moz-animation: live-call-animation 1s infinite; /* Fx 5+ */
	/*-o-animation: live-call-animation 1s infinite; /* Opera 12+ */
	animation: liveCallAnimation 1s infinite; /* IE 10+, Fx 29+ */
}

/*@-webkit-keyframes live-call-animation {*/
@keyframes liveCallAnimation {
	0%, 49% {
		background-color: #d91a1a;
		/*transition: background-color 0.5s ease;*/
	}

	50%, 100% {
		background-color: #ec1c2b;
		/*transition: background-color 0.5s ease;*/
	}
}

.notes-header {
	background-color: #f29d36;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-right-radius: 10px; 
	border-top-left-radius: 10px; 
	color: white;
	font-weight: 700;
	font-size: 15px;
}

.notes-content {
	height: 275px;
	padding: 15px;
	font-size: 14px;
	overflow: scroll;
	overflow-x: hidden;
}

.notes-content::-webkit-scrollbar {
  width: 10px;
}

.notes-content::-webkit-scrollbar-track {
  background: #fce1c2;
}

.notes-content::-webkit-scrollbar-thumb {
  background: #f29d36;
}

.target-form-header {
	background-color: #39b2bf;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-right-radius: 10px; 
	border-top-left-radius: 10px; 
	color: white;
	font-weight: 700;
	font-size: 15px;
}

.target-form {
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	justify-content: space-around;
}

.input-box {
	width: 100%;
	background-color: #f0f0f0;
	font-size: 12px;
	border-radius: 10px;
}

.input-box input {
	border: none;
	font-size: 12px;
	color: gray;
}
