.login-container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-image: url('../assets/img/FD_IntroBackground.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right;
}

.login-form {
	width: 600px;
}

.login-form input {
	border-radius: 50px;
	width: 100%;
	padding: 20px 30px 20px 30px;
	background-color: #F5F5F2;
	color: #9f9f9f;
	border: none;
}

.login-form input:focus {
	border-radius: 50px;
	width: 100%;
	padding: 20px 30px 20px 30px;
	background-color: #F5F5F2;
	color: black;
	border: none;
}

.login-form button {
	border-radius: 50px;
	padding: 5px 70px 5px 70px;
	margin-top: 30px;
	font-weight: 700;
}
